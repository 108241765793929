html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  margin: 0;
}

#root {
  height: 100%;
  width: 100%;
}
